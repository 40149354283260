/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';

// Emulates clarity card but with more flexible sizing
.dpa-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $clr-white;
  box-shadow: 0 remToBase(0.125) 0 0 $clr-light-midtone-gray;
  border: 1px solid $clr-light-midtone-gray;
  border-radius: remToBase(0.125);
  .dpa-card-title {
    color: $clr-black;
    font-size: remToBase(0.75);
    font-weight: 200;
    letter-spacing: normal;
    flex: 0 0 auto;
    padding: remToBase(0.5) remToBase(0.75) 0;
  }
  h6.dpa-card-sub-title {
    margin-top: 0;
    flex: 0 0 auto;
    padding: 0 remToBase(0.75);
  }
  .dpa-card-body {
    flex: 1 1 auto;
    min-height: remToBase(5);
  }
  .dpa-card-footer {
    padding: 0 remToBase(0.75);
    border-top: 1px solid $clr-light-gray;
  }
}

dpa-deem-incident-detail-potential-impact {
  .dpa-card {
    .dpa-card-title {
      font-size: remToBase(0.6);
      .counter-icon {
        width: px(22) !important;
        height: px(22) !important;
      }
    }
    .dpa-card-body {
      min-height: remToBase(4);
      .counter-numbers {
        .counter {
          font-size: $x4 !important;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

.metric-card-container {
  padding-top: $x4;
  display: flex;
  flex-direction: column;
}
