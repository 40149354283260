/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';

/* Styles for Home Page */
.service-card {
  &.card {
    box-shadow: none;
    min-width: x(30);
  }

  .card-title {
    min-width: 0;
  }

  .card-footer {
    display: flex;
    align-items: center;
    height: $x5;
    justify-content: space-between;
    padding: x(0.5) 0 x(0.5) x(2);
    .btn.btn-link {
      border: 0;
      font-size: $x2;
    }
    .btn.feature-link {
      font-size: px(14);
      font-weight: normal;
      text-transform: none;
    }
    background: $clr-color-neutral-50;
  }
}

.home-page {
  .collapsible-wrapper {
    border: 0;
    max-width: 100%;
    width: 100%;
    .nav-group {
      label {
        font-size: px(28);
        font-weight: 300;
        padding-left: x(1.5);
        width: auto;
      }
      .collapsible-action-control-wrapper {
        float: right;
      }
      .nav-list {
        clear: both;
        padding-left: 0;
        .card {
          .card-header {
            font-weight: normal;
          }
          .card-title {
            font-weight: normal;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .card-container {
    padding-bottom: x(0.5);
  }
}
