/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

/*
 * This styles are global. They allow to style children elements passed to sticky-header.
 *
 */
@import 'variables';

// actions
.sticky-header-actions {
  .dropdown-menu .btn[disabled] {
    cursor: not-allowed;
  }
}

.sticky-header.minimized {
  dpa-dashboard-filter {
    display: flex;
    order: 1; // ensure that the element is always at the right.
    align-items: center;
  }

  .hide-when-minimized {
    display: none;
  }
}

.action-buttons {
  white-space: nowrap;

  clr-dropdown {
    margin-right: $x2;
  }
}

// tabs
.sticky-header-tabs {
  margin-bottom: x(-3.5);

  .tab-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $clr-black;
  }

  .tab-value {
    font-size: x(3.33);
  }

  .active .tab-value {
    font-weight: 500;
  }

  &.minimized {
    flex: 1;

    .active-tab {
      vertical-align: middle;
      font-size: x(2.66);
      font-weight: 500;
      padding-right: $x4;

      &::before {
        content: ':';
        font-weight: normal;
      }
    }

    .tab-data {
      display: inline-flex;
      margin-left: $x1;

      > * {
        font-size: $x2;
        font-weight: normal;
        border: 1px solid $clr-light-gray;
        border-radius: remToBase(1);
        padding: 0 $x2;
        line-height: x(3.5);
        background: $clr-white;
        cursor: default;
      }
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tab-data {
        margin-left: $x4;
      }
    }
  }
}

dpa-dashboard .full {
  dpa-bread-crumb-viewer {
    padding-right: $x4;
    &.has-bookmark {
      padding-right: x(10.5);
    }
  }
}

// breadcrumbs
.sticky-header h2 a {
  color: $clr-gray;
}

.text-ellipsis {
  vertical-align: top;
}

.sticky-header h2 > cds-icon {
  margin: 0 $x1;
}

.sticky-header .dashboard-filter-container .type-ahead-dropdown {
  &.align-right {
    right: auto; // we don't want to align right in the dashboard filters
  }
}

// fullscreen mode
body:fullscreen {
  width: 100%; // fix for Safari

  .sidenav {
    display: none;
  }

  dpa-fluid-height.minimized {
    box-shadow: none;
  }
}

// ie11
body:-ms-fullscreen {
  .sidenav {
    display: none;
  }

  dpa-fluid-height.minimized {
    box-shadow: none !important;
  }
}

dpa-sticky-header-v2 {
  dpa-header-meta + dpa-header-meta {
    margin-left: 0;
  }
  dpa-owner-info-tag {
    .owner-details {
      margin-right: px(6) !important;
    }
  }
  dpa-slide-over {
    dpa-full-page {
      position: fixed !important;
      dpa-tag-filter {
        .tag-box {
          flex-wrap: wrap;
        }
      }
      dpa-filter-group-date-range {
        display: block;
      }
    }
  }
  dpa-sticky-header-tabs {
    display: block;
    margin-bottom: x(-3);
    &.minimized {
      margin-bottom: 0;
    }
    .sticky-header-tabs.complex-tabs > a {
      justify-content: flex-start !important;
    }
  }
  .minimized {
    dpa-last-updated {
      margin-top: px(3);
    }
  }
  dpa-modal {
    .hide-in-modal {
      display: none !important;
    }
  }
  .angle-icon {
    margin-right: px(-6);
  }
  .widget-badge-info {
    width: $x4;
    margin-bottom: px(2);
    padding-left: px(5);
  }
  .platform-icon.platform-icon-in-header {
    display: inline-block;
    vertical-align: middle;
    background-color: $clr-light-gray;
    background-size: 60%;
    border-radius: x(0.66);
    width: $x4;
    height: $x4;

    svg {
      width: 60%;
      margin: auto;
    }
  }
}

.sticky-header.minimized .sticky-header-actions {
  dpa-tag-filter {
    display: inline-flex;
    .btn {
      font-size: x(1.6);
    }
  }
}

dpa-sticky-header.has-tabs .minimized {
  .page-title {
    font-weight: 200;
  }

  .sticky-header-content {
    align-items: center;
  }
}

.sticky-header.full .sticky-header-content:empty {
  margin-top: x(3);
}

.sticky-header.full .sticky-header-content-v2.sticky-header-content:empty {
  margin-top: 0;
}
