/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';
@import 'mixins';

dpa-alert-banner:not(:empty) {
  & ~ dpa-home-page,
  & ~ dpa-getting-started {
    .cloud-platform-footer {
      padding-bottom: x(14.5);
    }
  }
}

.label {
  vertical-align: text-top;
}

.preview-header {
  padding: remToBase(1) remToBase(0.5) 0;
  h2 {
    margin: 0;
  }
  .label {
    margin-left: remToBase(0.3);
  }
}

.text-ellipsis {
  @include overflow-ellipsis();
}

.text-danger {
  color: $dpa-red !important;
}

.text-success {
  color: $clr-green-dark-midtone !important;
}

.link-normal {
  color: $clr-action-blue;
  cursor: pointer;
  &:visited {
    color: $clr-action-blue;
  }
}

.link-disabled {
  color: $clr-dark-midtone-gray !important;
  cursor: not-allowed;
  pointer-events: none;
}

.signpost-flex-wrap {
  overflow: auto;
}

.clickable {
  cursor: pointer;
}

.page-wrapper {
  padding: remToBase(1.6667);
  overflow-y: scroll;
  height: 100%;
  &.detail-page {
    padding: 0 0 x(12) 0;
  }
}

.detail-page-section {
  padding: 0 remToBase(1.6) remToBase(2);
}

// hides the 'x' icon ie puts inside input fields
input::-ms-clear {
  width: 0;
  height: 0;
}

.page-metadata {
  margin-top: remToBase(1);
  font-weight: normal;
  color: $clr-gray;

  p {
    display: inline-block;
    font-size: remToBase(0.5417);

    &:first-of-type {
      margin-right: remToBase(1);
    }
  }

  .metadata-label {
    font-weight: 600;
  }
}

.spinner-container-centered {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
}

.loading-spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}

// used to mark the label as optional
// setting the font-weight to normal since all labels are bold
.clr-control-label {
  .optional {
    font-weight: normal;
  }
}

dpa-accordion.single-accordion-step {
  .accordion-title {
    display: none;
  }
}

.bookmark-tooltip {
  color: $clr-gray;
  .is-solid {
    color: $clr-action-blue;
  }
}

.btn.btn-link.doc-link {
  font-weight: normal;
  text-transform: none;
}

button.btn.dpa-icon-link {
  margin: 0;
  padding: 0;
  min-width: $x4;
  color: $clr-dark-gray;
}

.screen-reader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: px(0);
  margin: px(-1);
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: px(1);
}

.go-tag {
  min-width: px(65);
  display: inline-block;
  border: px(1) solid $clr-color-neutral-400;
  padding-inline: px(14);
  border-radius: px(4);
  text-align: center;
  font-size: px(12);
  background: $clr-near-white;
  font-weight: bold;
  text-transform: uppercase;

  &.tag-custom {
    text-transform: capitalize;
    font-weight: normal;
  }
  &.tag-if {
    background: $dpa-go-tag-if-color;
  }
  &.tag-then {
    background: $dpa-go-tag-then-color;
  }
  &.tag-else {
    background: $dpa-go-tag-else-color;
  }
  &.tag-when {
    background: $clr-near-white;
  }
}

.text-with-nav-link {
  pointer-events: none;
  a {
    pointer-events: auto;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.bold {
  font-weight: $clr-font-weight-bold;
}

.status-bg-colors {
  &.good {
    background-color: $dpa-stoplight-green;
  }

  &.neutral {
    background-color: $dpa-stoplight-yellow;
  }

  &.bad,
  &.poor {
    background-color: $dpa-stoplight-red;
  }
}

.status-colors {
  &.good {
    color: $dpa-stoplight-green;
  }

  &.neutral {
    color: $dpa-stoplight-yellow;
  }

  &.bad,
  &.poor {
    color: $dpa-stoplight-red;
  }
}

.full-width {
  width: 100%;
}

input[type='number'] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.border-top-open {
  border-top: none;
}

// SLIDE OVER
.slide-over {
  display: flex;
  flex-direction: column;
  height: 100%;

  .slide-over-header {
    height: x(8);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $clr-near-white;
    border-bottom: px(1) solid $clr-light-midtone-gray;

    cds-icon {
      color: $clr-dark-gray;
    }
  }

  .slide-over-content {
    flex: 1;
    overflow: scroll;
  }

  .slide-over-footer {
    height: x(8);
    border-top: px(1) solid $clr-light-midtone-gray;

    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
}

dpa-device-profile-summary-item-view {
  .tab-container {
    margin-left: px(3);
  }

  .line-spark {
    height: x(43);
  }

  .performance-avg {
    padding: x(3);
    margin-left: px(-2);

    .performance-row {
      border: solid $clr-light-midtone-gray px(1);
      font-weight: $clr-font-weight-semibold;
      padding: px(4);

      &:not(:last-child) {
        border-bottom: none;
      }

      .performance-key {
        font-weight: $clr-font-weight-regular;
      }
    }
  }
}

.no-border {
  border: none !important;
}

.no-border-top {
  border-top: none;
}

.no-border-bottom {
  border-bottom: none;
}

.display-flex {
  display: flex;
}

.display-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.display-flex-end {
  display: flex;
  align-items: flex-end;
}

.img-border-shadow {
  border-radius: px(4);
  background: $clr-white;
  box-shadow: px(0) px(2) px(2) px(0) rgba(0, 0, 0, 0.25);
}

dpa-type-ahead.no-underline {
  .clr-input {
    border: none;
  }
}

dpa-global-header-panel-user dpa-tree-view .content-wrapper > .matching-text {
  color: $clr-white;
  &:hover {
    color: $clr-dark-midtone-gray !important;
  }
}

dpa-deem-organization-overview {
  .card {
    .card-header {
      border-bottom: none;
    }
    .card-block {
      .data-box-wrapper {
        display: flex;
        justify-content: space-around;
      }
      .spinner-container-centered {
        min-height: px(57);
      }
    }
  }
}
