/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

@import '../../../../../node_modules/@dpa/ui-common/styles/mixins';
@import 'variables';

span.large-dot {
  font: {
    size: remToBase(1.2);
    weight: $clr-font-weight-bold;
  }
  line-height: remToBase(0.5833);
  margin: 0 remToBase(0.2);
  display: inline-block;
  position: relative;
  top: remToBase(0.15);
}

dpa-query-builder .condition button.operator-toggle {
  gap: unset;
}

.hidden {
  display: none !important;
}
