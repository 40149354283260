/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

@import 'mixins';
@import 'variables';
@import 'z-index';

// Work around for Clarity Issue https://github.com/vmware/clarity/issues/4123
// Looks like Clarity auto closes the popover when IE has scrollbar in the body
// See https://github.com/vmware/clarity/blob/8a1ad9674032a1a3bf1119abf507f3a61d188689/src/clr-angular/data/datagrid/datagrid-action-overflow.ts#L46
// and https://github.com/vmware/clarity/blob/2e195c9d15060aca721c9175462f716217541c13/src/clr-angular/utils/popover/providers/popover-events.service.ts#L48
body {
  overflow: hidden;
}

/* fix wrapping text in app-level alert banner */
.alert-app-level .alert-item > span,
.alert-app-level .alert-text {
  flex-shrink: 1;
}

// Clarity reserves remToBase(1.16667) padding-right for their tooltip
// This makes up down arrow of number selectors oddly placed
// This override reserves the padding only when the tooltip is visible
.tooltip.tooltip-validation:not(.invalid) > input[type='number'] {
  padding: 0 remToBase(0.25);
}

.nav .nav-link:focus {
  user-select: none;
}

.sidenav .nav-group .nav-list,
.sidenav .nav-group label {
  margin: 0;
  cursor: auto;
}

// overriding the logo&text styles and searchbox in header
.header {
  .branding {
    @media screen and (max-width: remToBase(48.06)) {
      min-width: auto;
    }
  }

  .search {
    label {
      @media screen and (max-width: remToBase(48.06)) {
        width: auto;
      }
    }
  }
}

.customize-mode {
  .home-page-header {
    display: none;
  }
  .home-page-footer {
    display: none;
  }
}

.home-page-content {
  .sidenav {
    .nav-group {
      margin-top: remToBase(1.4);
      margin-bottom: remToBase(1.4);
    }
  }

  .cdk-drag {
    cursor: grab;

    .sidenav {
      label {
        transition: all 0.1s ease-in-out;

        &::after {
          transition: opacity 0.2s ease-in-out;
        }
      }
    }
  }

  .cdk-drop-list {
    .cdk-drop-list-container {
      border-top: px(1) dashed $clr-action-blue;
    }
    .cdk-drag-wrapper {
      .cdk-drag,
      .cdk-drag-placeholder {
        cursor: grab;

        .section-drag-item-container {
          border-bottom: px(1) dashed $clr-action-blue;

          .section-drag-wrapper {
            border: px(1) solid $clr-light-midtone-gray;
            background-color: $clr-white;
            box-shadow: 0 px(3) 0 $clr-lighter-midtone-gray;
            border-radius: px(3);
            margin-top: remToBase(0.9);
            margin-bottom: remToBase(0.9);
            max-width: 100%;
            width: 100%;
            transition: all 0.2s ease-in-out;
            overflow: visible;

            .section-drag {
              align-items: center;
              margin-top: remToBase(0.9);
              margin-bottom: remToBase(0.9);
              padding: 0 remToBase(1.1);

              & > label {
                cursor: grab;
                font-size: remToBase(1.15);
                font-weight: 300;
                padding: 0;
                width: auto;

                &::after {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
    }

    &.cdk-drop-list-dragging {
      cursor: grabbing;

      .cdk-drag-wrapper {
        & > div {
          transition: transform 0.15s ease-in-out;
        }
      }

      .cdk-drag,
      .cdk-drag-placeholder {
        cursor: grabbing;
      }

      .cdk-drag-placeholder {
        .section-drag-item-container {
          border-bottom: px(4) solid $clr-action-blue;

          .section-drag-wrapper {
            border: px(1) solid $clr-action-blue;
            box-shadow: 0 px(3) 0 $clr-action-blue;
          }
        }
      }
    }

    &:not(.cdk-drop-list-dragging) {
      .cdk-drag {
        &:hover,
        &:focus-within {
          .move-section-buttons {
            opacity: 1;
          }

          .section-drag-wrapper {
            border: px(1) solid $clr-action-blue;
            box-shadow: 0 px(3) 0 $clr-action-blue;
          }
        }
      }
    }
  }
}

.btn {
  user-select: none;
}

.btn.btn-sm.btn-thin {
  padding: 0;
  margin: 0;
  line-height: unset;
  height: unset;
}

.clr-wizard .clr-short-modal .modal-content {
  max-height: remToBase(18);
}

.dropdown-menu > .dropdown > .dropdown-menu {
  border-color: $clr-light-midtone-gray;
}

/* intel-6644 - disable modal page if the step is complete */
clr-wizard li.complete .clr-wizard-stepnav-link {
  &:hover {
    color: inherit;
  }
  outline: none;
  opacity: 0.65;
  cursor: not-allowed;
}

.datagrid-host {
  padding-top: 0;
}

// additional icon colors beyond the clarity predefined classes:
// is-highlight/is-info/is-blue, is-danger/is-error/is-red, is-success/is-green, is-white, is-warning (yellow)
cds-icon {
  &[shape='ellipsis-vertical'] {
    color: $clr-color-neutral-600;
  }
  &[shape='close'] {
    color: $clr-color-neutral-600;
  }
  &[shape='view-columns'] {
    color: $clr-color-neutral-600;
  }
  &[shape='angle'] {
    color: $clr-color-neutral-600;
  }
  &[shape='info-standard'] {
    color: $clr-color-neutral-600;
  }
  &.is-gray {
    @include setIconColor($clr-gray);
  }
  &.is-dark-gray {
    @include setIconColor($clr-dark-gray);
  }
  &.is-dark-midtone-gray {
    @include setIconColor($clr-color-neutral-600);
  }
  &.is-yellow-light-midtone {
    @include setIconColor($clr-yellow-light-midtone);
  }
}

dpa-data-grid .datagrid {
  max-width: 100%;
  margin-top: 0;
  .datagrid-table-wrapper {
    overflow: auto;
  }
  .datagrid-head,
  .datagrid-body {
    @include z-index(content);
    display: table;
    width: 100%;
  }
  .datagrid-column-title {
    width: 100%;
  }
  .datagrid-cell clr-signpost .signpost-flex-wrap .signpost-content-body {
    overflow: auto;
  }
  .datagrid-column-flex {
    width: 100%;
  }
  .datagrid-fixed-column {
    max-width: remToBase(1.7);
    min-width: remToBase(1.7);
    .datagrid-column-title {
      padding-top: remToBase(0.108);
    }
  }
  .datagrid-row-actions {
    cds-icon {
      transition: opacity 0.25s ease-in-out;
      -moz-transition: opacity 0.25s ease-in-out;
      -webkit-transition: opacity 0.25s ease-in-out;
      opacity: 0.4;
      &:hover {
        opacity: 1;
      }
    }
  }
}

/* INTEL-26699 - remove scroll from datagrid while data is loading */
dpa-data-grid {
  .loadingmode {
    .datagrid-table {
      overflow: hidden;

      .datagrid-placeholder {
        .datagrid-placeholder-image {
          display: none;
        }

        span[role='gridcell'] {
          width: 100%;
        }
      }

      .datagrid-column {
        button {
          pointer-events: none;
          cds-icon.sort-icon {
            pointer-events: none;
          }
        }
      }
    }
  }

  .datagrid-header {
    z-index: auto;
  }
}

dpa-data-explorer-schema dpa-data-grid clr-datagrid {
  height: auto !important;
}

dpa-choose-data-access-policy-modal dpa-data-access-policy-list dpa-data-grid clr-datagrid {
  min-height: x(40);
}

dpa-slide-over dpa-device-profile-timeline-summary dpa-data-grid clr-datagrid {
  min-height: x(100);
}

.result-table clr-datagrid {
  .datagrid-spinner {
    width: calc(100% + remToBase(2));
  }
}

/* INTEL-43813 - datagrid with column headers but no rows */
dpa-data-grid.empty-grid {
  max-width: 95%;

  clr-dg-placeholder {
    max-width: calc(100vw - #{remToBase(1)});
    padding: $x8 0;
  }

  &.read-only clr-datagrid {
    max-width: 100%;
    clr-dg-placeholder {
      max-width: calc(100vw - #{remToBase(14)});
      padding: $x8 0;
    }
  }
}

// single column, list view for data grids
dpa-data-grid.list-view {
  .datagrid-outer-wrapper {
    display: block;
  }

  .datagrid {
    border: none;
    border-radius: 0;
  }

  .datagrid-header,
  .datagrid-placeholder-container {
    display: none;
  }

  .datagrid-row {
    border-top: px(1) solid $clr-light-gray;

    &:first-of-type {
      border-top: none;
    }
  }

  .datagrid-cell {
    padding: 0;
  }

  .datagrid-footer {
    background-color: $clr-white;
    border: none;
    border-top: px(1) solid $clr-light-midtone-gray;

    > :not(.pagination) {
      display: none;
    }

    .pagination {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .pagination-description {
        margin: 0;
      }

      > :not(.pagination-description, .pagination-list) {
        display: none;
      }
    }
  }
}

/* tree view */
.clr-treenode-caret {
  align-self: flex-start;
  color: $clr-color-neutral-600;
}

/* remove blue glow from toggle switches */
.toggle-switch input[type='checkbox']:focus + label::before {
  box-shadow: none;
}

/* show the "x" as disabled when the clrModalPreventClose option is used */
clr-modal.not-closable .modal-content button.close {
  display: none;
}

ngx-charts-legend.chart-legend {
  // clarity style spills over onto .chart-legend used by ngx-charts
  header {
    display: block;
    color: inherit;
    background-color: inherit;
    height: auto;
  }
}

.dropdown .btn.btn-more {
  padding-right: remToBase(0.5);
}

.datagrid-foot .column-switch-wrapper .column-switch {
  @include z-index(datagrid-column-switch);
}

.template-search-data-grid,
.integrations-wrapper {
  .card {
    &.selected {
      background-color: $dpa-card-selected-bg-color;
      transition: background-color 0.2s ease-in-out;

      .card-block {
        border-bottom-color: $dpa-card-selected-border-color;
        transition: border-bottom-color 0.2s ease-in-out;
      }
    }
  }
}

.card-footer {
  .btn {
    line-height: $x4;
    height: $x4;
  }
}

// the expandable row in data-grid gets a white bg color instead of the default gray
clr-dg-row-detail {
  background-color: $clr-white !important;
}

clr-stepper-panel.visible-override {
  .clr-accordion-content {
    overflow: visible;
  }
}

dpa-data-explorer dpa-data-grid.empty-grid {
  .datagrid-table {
    width: 100%;
  }
}

// INTEL-32916 Layout issue in list mode of automation workflows template
dpa-template-search-grid-list.template-page dpa-data-grid {
  .datagrid {
    overflow-y: visible;
    .datagrid-table-wrapper {
      overflow-y: visible;
    }
    // For tags overlay to be visible in template search page
    .datagrid-table {
      outline: none;
      position: static;
    }
    .datagrid-row {
      border-left: px(1) solid $clr-lighter-midtone-gray;
      border-right: px(1) solid $clr-lighter-midtone-gray;
    }
  }
}

dpa-horizon-counter-user-table.template-page dpa-data-grid {
  width: 100%;
}

// adds a scrollbar to the page for modals which are large and when the browser is scaled to 200%
clr-modal {
  .modal {
    overflow-y: auto;
  }
  .modal-dialog {
    max-height: 100%;
  }
  // INTEL-23206 Clarity 4 upgrade issue
  .modal-title-wrapper {
    width: 100%;
  }
}

// INTEL-21281 Changed global search header text for Accessibility VPAT
.header .search input[type='text'] {
  color: $dpa-light-gray;
}

// overriding the hamburger styles in mobile and tablet view
.main-container {
  .header-hamburger-trigger {
    padding: 0 $x2;
    background-color: $dpa-header-color;
    opacity: 1;

    span {
      display: block;
      margin: 0 auto;
    }
  }

  .header-overflow-trigger {
    background-color: $dpa-header-color;
    opacity: 1;
  }
}

clr-tree-node.adjusted-padding {
  .clr-treenode-content:first-child {
    margin-left: unset;
    padding-left: px(8);
  }
}

dpa-local-search {
  .clr-input {
    border-bottom: px(1) solid $clr-color-neutral-500;
  }
}

dpa-template-search-grid-list.template-datagrid {
  .datagrid-row-flex {
    .datagrid-row-scrollable {
      width: calc(100% - 38px); // stylelint-disable-line meowtec/no-px
    }
  }
}

dpa-create-edit-test-wizard,
dpa-wizard-with-sticky-header {
  .wizard-with-sticky-header {
    .sticky-header {
      padding-left: remToBase(1);
      padding-right: remToBase(0.5);

      &.minimized {
        padding-left: remToBase(1);
        padding-right: remToBase(0.6);
      }
    }
  }
}

dpa-role-details {
  .stack-view .stack-children .stack-block-label,
  .org-name > .stack-view .stack-children clr-stack-block .stack-block-label {
    background-color: $clr-white;
  }
}

dpa-banner-notification {
  .alerts-pager {
    border-right: px(1) solid $clr-color-neutral-300;
  }
  .alert-app-level {
    .alert-items {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      flex-flow: row nowrap;
    }
    .alert-item {
      padding-top: x(1);
      padding-bottom: x(1);
    }
  }
}

dpa-type-ahead,
dpa-type-ahead-multi {
  .type-ahead-input[disabled] {
    background-color: rgba(255, 255, 255, 0);
  }
}

.stack-view-container {
  .stack-view {
    height: auto;
  }
}

// Key Selector
dpa-key-selector clr-tree-node.entity > .clr-tree-node-content-container {
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin: $x1;
  height: px(24);
  .clr-treenode-content {
    flex: 0;
    white-space: nowrap;
    line-height: px(24);
    font-size: px(12);
  }
  button.clr-treenode-caret {
    background-color: $dpa-card-selected-bg-color;
    position: relative;
    height: px(24);
    width: px(24) !important;
    flex: 0;
    padding: 0 px(4);
    cds-icon {
      width: px(12);
      height: px(12);
    }
    &::after {
      content: '';
      position: absolute;
      left: px(24);
      bottom: 0;
      border-left: px(12) solid #{$dpa-card-selected-bg-color};
      border-top: px(12) solid transparent;
      border-bottom: px(12) solid transparent;
    }
  }
}

.clr-vertical-nav .nav-text {
  height: 100% !important;
}

clr-tree {
  .clr-treenode-caret {
    min-width: unset;
    min-height: unset;
  }
}

clr-alert {
  display: block;
}
