/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

// prettier-ignore
$z-index: (
'auto': 'auto',
'content': 0,
'web-error': 1,
'bookmark': 2,
'canvas-node-options': 5,
'canvas-node-reordering': 6,
'alert-banner': 10,
'button-behind': 10,
'button-credential-input': 10,
'circle-container': 10,
'drill-down-navigator-middle-line': 10,
'fluid-height': 10,
'lookup-variable-selector': 10,
'organization-tree-input-filter': 10,
'solutions-banner-content': 10,
'global-orchestrator-header': 15,
'side-panel-back':19,
'alert-banner-page-level': 20,
'button-cve-floating-close': 20,
'button-front': 20,
'datagrid-column-switch': 20,
'drill-down-navigator-circle-group': 20,
'nav-link': 20,
'side-panel-front': 20,
'dropdown-menu': 100,
'type-ahead-dropdown': 502,
'sticky-header': 503,
'key-selector-dropdown-container': 1000,
'chart-selector-list': 1001,
'gridster-selected-item': 1001,
'full-page': 1010,
'page-backdrop': 1100,
'page-right-panel': 1200,
'gloabal-search-dropdown': 1300,
'global-search-typeahead-dropdown': 1300,
'date-time-picker': 10000,
'key-selector-dropdown-tooltip': 10000,
'page-top-search-panel': 10000,
'dpa-tooltip-view': 10001,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}

@mixin z-index-important($key) {
  z-index: z-index($key) !important;
}
